import React from "react";
import { showErrorModal, showInfoModal } from "../../../modals";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ThanksForYourPurchase from "../../../images/thanks-for-your-purchase.svg";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/hr';
import { getCurrentLanguageTexts } from "../../../constants/seasonPassPersonalInfoLangs";
import ROUTES from "../../../constants/routes";
import { getCustomURLs } from "../../../helpers/getCustomURLs";
import { getRegion } from "../../../helpers/getRegion";

const MySwal = withReactContent(swal);

export const showSuccessPaymentModal = (
    {
        buyerEmail,
        additionalPaymentStatus,
        paymentDate,
        transactionId,
        authCode,
        buyMoreUrl,
        showConfirmButton = true
    }) => {

    const txt = getCurrentLanguageTexts();
    moment.locale('hr');
    const { theme } = getRegion();

    if (theme === 'france') {
      moment.locale('fr');
    }

    MySwal.fire({
        html: (
            <div className="season-pass-info-modal">
                <div className="season-pass-info-modal__body">
                    <h3>{txt.sellPageModals["Thanks for Your Purchase!"]}</h3>
                    <img src={ThanksForYourPurchase} alt="Thanks for Your Purchase!" />
                    <p>{txt.sellPageModals["We will send all the details to your inbox"]}<b>{buyerEmail}</b>{txt.sellPageModals[" and look forward to meeting you"]}</p>
                    <p className="season-pass-info-modal__additionalText">
                        ({txt.sellPageModals["Payment Status: "]} {additionalPaymentStatus || 'null'},
                        {txt.sellPageModals["Payment Date: "]} {moment(paymentDate).format("DD MMMM YYYY HH:mm")},
                        {txt.sellPageModals["Transaction Id: "]} {transactionId || 'null'}, {txt.sellPageModals["Auth Code: "]} {authCode || 'null'})
                    </p>
                </div>
            </div>
        ),
        width: "900px",
        allowEscapeKey: false,
        padding: "0px 0px 48px 0px",
        customClass: "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        allowOutsideClick: false,
        allowEnterKey: false,
        showConfirmButton: showConfirmButton,
        confirmButtonText: txt.sellPageModals["Buy More"],
    }).then((result) => {
        if (result.value && !!buyMoreUrl) {
            window.location.href = getCustomURLs().PUBLIC_URL + buyMoreUrl;
        }
    })

    /* showInfoModal({
        image: ThanksForYourPurchase,
        title: "Thanks for Your Purchase!",
        text: `We will send all the details to your inbox (${buyerEmail}) and look forward to meeting you`,
        additionalText: `(Payment Status: ${additionalPaymentStatus
            }, Payment Date: ${moment(paymentDate).format(
                "DD MMMM YYYY HH:mm"
            )}, Transaction Id: ${transactionId}, Auth Code: ${authCode
            })`,
        customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
    }); */
}

export const showErrorPaymentModal = ({
    additionalPaymentStatus,
    paymentDate,
    transactionId,
    authCode
}) => {
    const txt = getCurrentLanguageTexts();

    showErrorModal({
        text: txt.sellPageModals["It seems to have failed to complete the payment, please try again"],
        customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        additionalText: `(${txt.sellPageModals["Payment Status: "]}${additionalPaymentStatus
            }, ${txt.sellPageModals["Payment Date: "]}${moment(paymentDate).format(
                "DD MMMM YYYY HH:mm"
            )}, ${txt.sellPageModals["Transaction Id: "]}${transactionId}, ${txt.sellPageModals["Auth Code: "]}${authCode
            })`,
    });
}

export const showProcessingPaymentModal = ({
    transactionId,
}) => {
    const txt = getCurrentLanguageTexts();

    showInfoModal({
        image: ThanksForYourPurchase,
        text: txt.sellPageModals["Your transaction is in processing"],
        customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        additionalText: `(${txt.sellPageModals["Transaction Id: "]}${transactionId})`,
    });
}

export const showRefundPaymentModal = ({
    transactionId,
}) => {
    const txt = getCurrentLanguageTexts();

    showErrorModal({
        text: txt.sellPageModals["Transaction was refunded"],
        customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        additionalText: `(${txt.sellPageModals["Transaction Id: "]}${transactionId})`,
    });
}