import React from "react";

export const getSeatDescription = (seat) => {
  let description = <>{seat.area}-{seat.side}-{seat.row}-{seat.seat}</>;

  if (seat.ticketTemplate.ticketName) {
    description = <b>{seat.ticketTemplate.ticketName }, {description} </b>;
  }

  return (
    <div>
      {description}
      <div>
        <strong>Refund amount:</strong> {seat.ticketTemplate.price}
      </div>
    </div>
  );
};
