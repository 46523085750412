import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ConfirmRefundTicketsModal } from "./ConfirmRefundTicketsModal";
import { SeatsDescriptionModal } from "../../../../../../components/Transactions/SeatsDescriptionModal/SeatsDescriptionModal";

const MySwal = withReactContent(Swal);

const closeModalHandler = (e) => {
  MySwal.close();
};

export const RefundTicketsModal = ({
  token,
  transaction,
  setActionLoading,
  getEventTransactions,
}) => {
  const possibleSeatsToRefund = transaction
    .seats.filter((seat) =>
      !seat.refund
      && seat.isActual
      && seat.ticketTemplateId !== process.env.REACT_APP_NEW_SEASON_NFC_CARD_TICKET_TEMPLATE_ID);

  const idsSeats = possibleSeatsToRefund.map((seat) => seat.id);
  const [checkedSeats, setCheckedSeats] = useState(idsSeats);
  const [totalRefundAmount, setTotalRefundAmount] = useState(0);

  const canRefund = checkedSeats.length > 0;

  const handleCheck = (e) => {
    const seatId = e.target.dataset.related;
    var updatedSeats = [...checkedSeats];

    if (e.target.checked) {
      updatedSeats = [...checkedSeats, seatId];
    } else {
      updatedSeats.splice(checkedSeats.indexOf(seatId), 1);
    }

    setCheckedSeats(updatedSeats);
  };

  const refundTicketsHandler = () => {
    const seats = possibleSeatsToRefund.filter((seat) =>
      checkedSeats.includes(seat.id)
    );

    MySwal.fire({
      title: "Are you sure you want to refund tickets?",
      html: 
          <ConfirmRefundTicketsModal
            token={token}
            seats={seats}
            setActionLoading={setActionLoading}
            getEventTransactions={getEventTransactions}
          />,
      showConfirmButton: false,
      customClass: "confirm-refund-tickets-modal",
    });
  };

  useEffect(() => {
    const chekedSeatsInfo = possibleSeatsToRefund.filter(s => checkedSeats.includes(s.id));
    const total = chekedSeatsInfo.reduce((sum, seat) => {
      return sum + seat.ticketTemplate.price
    }, 0)

    setTotalRefundAmount(total)
  }, [checkedSeats])

  return (
    <>
      <div className="label-block">
        <span className="swal2-label">Name</span>
        <span className="swal2-label">
          <b>{transaction.fullName}</b>
        </span>
      </div>
      <div className="label-block">
        <label className="swal2-label">Email</label>
        <label className="swal2-label">
          <b>{transaction.buyerEmail}</b>
        </label>
      </div>
      {possibleSeatsToRefund.length > 0 && (
        <div className="seats-list">
          <h2 className="title">Seats list</h2>
          <SeatsDescriptionModal
            seats={possibleSeatsToRefund}
            checkedSeats={checkedSeats}
            handleCheck={handleCheck}
          />
        </div>
      )}
      {possibleSeatsToRefund.length > 1 && (
        <div className="total">
          <strong>Total refund amount:</strong> {totalRefundAmount}
        </div>
      )}
      <div className="swal2-actions">
        <button
          className="swal2-cancel swal2-styled"
          onClick={closeModalHandler}
        >
          Cancel
        </button>
        <button
          className="swal2-confirm swal2-styled"
          disabled={!canRefund}
          onClick={refundTicketsHandler}
        >
          Refund
        </button>
      </div>
    </>
  );
};
